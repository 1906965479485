<script>
import { mapGetters, mapActions } from 'vuex';
import embeddedPerformance from './components/embedded-performance.vue';

export default {
  components: { embeddedPerformance },
  props: {
    statColumns: {
      type: Object,
    },
    enableDefaults:{
      type: Boolean,
      required: false,
    },
    assignmentMode: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    allowSelect: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    selected:{
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      mainChartYAxisLogarithmicType: false
    }
  },
  computed: {
    ...mapGetters('instruments', {
      order: 'order',
      loading: 'loading',
      processing: 'processing',
      classifiers: 'classifiers',
      items: 'items',
      hasAccess: 'hasAccess',
      defaults: 'defaults',
      normalized: 'normalized',
     
    }),
    anyProcessing() {
      const keys = Object.keys(this.processing);
      return keys.find(key => this.processing[key]) ? true : false;
    },
    isBusy () {
      return this.loading;
    },
    
  },
  methods: {
    ...mapActions('instruments', {
      removeAccess: 'removeAccess',
      addAccess: 'addAccess',
      addDefault: 'addDefault',
      removeDefault: 'removeDefault',
      setOrder: 'setOrder'
    }),
    async addAccessForAll() {
      this.items.forEach(async i => {
        if(!this.hasAccess[i.id]) {
          await this.addAccess(i.id);
        }
      });
    },
    async removeAccessForAll() {
      this.items.forEach(async i => {
        if(this.hasAccess[i.id]) {
          await this.removeAccess(i.id);
        }
      });
    },
    select(instrument) {
      this.$emit('select', instrument);
    },
    unselect(instrument) {
      this.$emit('unselect', instrument);
    },
    onOrder(colName) {
      const o = { ...this.order };

      if(o.by === colName) {
        o.asc = !o.asc;
      } else {
        o.by = colName;
        o.asc = true;
      }

      this.setOrder(o);
    }
  }
};
</script>

<template>
  <div>
    <b-form-checkbox v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
      style="display:inline-block;">

    </b-form-checkbox>{{$t("pages.indexView.yAxisLogarithmic")}}
    <div class="row no-gutters" >
      <div class="col-lg-6">
        <ul class="list-inline my-3 ecommerce-sortby-list" >
          <li class="list-inline-item">
            Legend: 
          </li>
          <li class="list-inline-item">
            <div class="badge badge-warning" >Provider</div>
          </li>
        <li class="list-inline-item">
            <div class="badge badge-primary" >Asset class</div>
          </li>
          <li class="list-inline-item">
            <div class="badge badge-success">Instrument type</div>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 text-right">
        
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-lg-4" v-for="item in items" :key="item.id">
        <div class="product-box" style="padding-bottom:0">
        
          <embedded-performance :instrument="item" :normalized="normalized" :colors="['#FE6C2D']" :logarithmic="mainChartYAxisLogarithmicType"></embedded-performance>
          <ul class="list-inline my-3 ecommerce-sortby-list" style="position: absolute;top: -10px;left: 5px; right:40px;">
            <li class="list-inline-item">
              <div class="badge badge-warning" v-if="item.classifiers.provider">
                {{classifiers.map.provider.map[item.classifiers.provider]}}
              </div>
            </li>
            <li class="list-inline-item" v-if="item.classifiers.assetClass">
              <div class="badge badge-primary" >
                {{classifiers.map.assetClass.map[item.classifiers.assetClass]}}
              </div>
            </li>
            <li class="list-inline-item" v-if="item.classifiers.instrumentType">
              <div class="badge badge-success"> 
                {{classifiers.map.assetClass.map[item.classifiers.instrumentType]}}
              </div>
            </li>
          </ul>
          <div class="text-center">
            <p class="font-size-12 mb-1" v-if="item.classifiers.indexType">
              Index type: {{classifiers.map.indexType.map[item.classifiers.indexType]}}
            </p>
            <h5 class="font-size-15">
              <router-link :to="{ name: 'instrument-view', params: {instrumentId: item.id}}" class="text-dark">{{item.displayName}}</router-link>
            </h5>
            <p class="font-size-12 mt-3 mb-0 text-info" v-if="item.classifiers.geography">
              {{classifiers.map.geography.map[item.classifiers.geography]}}
            </p>
          </div>
          
          <table class="mt-4 table table-sm table-hover table-borderless small">
            <tr v-if="statColumns.ytd.visible || statColumns.mtd.visible">
              <td>YTD</td>
              <td class="text-primary"> {{item.data.performance.ytdFormatted}} <span v-if="item.data.performance.ytdFormatted">%</span></td>
              <td>MTD</td>
              <td class="text-primary"> {{item.data.performance.mtdFormatted}} <span  v-if="item.data.performance.mtdFormatted">%</span></td>
            </tr>
            <tr v-if="statColumns.annualisedReturn.visible || statColumns.realisedVolatility.visible">
              <td class="no-wrap">{{$t('tables.indices.columns.annualisedReturn')}}</td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.annualisedReturnFormatted}} <span  v-if="item.data.mainStatistic.values.annualisedReturnFormatted">%</span>
                </span>
              </td>
              <td class="no-wrap">{{$t('tables.indices.columns.realisedVolatility')}}</td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.realisedVolatilityFormatted}} <span  v-if="item.data.mainStatistic.values.realisedVolatilityFormatted">%</span>
                </span>
              </td>
            </tr>
            <tr v-if="statColumns.downsideVolatility.visible || statColumns.maxDrawdown.visible">
              <td class="no-wrap">{{$t('tables.indices.columns.downsideVolatility')}}</td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.downsideVolatilityFormatted}} <span  v-if="item.data.mainStatistic.values.downsideVolatilityFormatted">%</span>
              </span>
              </td>
              <td class="no-wrap">
                {{$t('tables.indices.columns.maxDrawdown')}}
                </td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.maxDrawdownFormatted}} <span  v-if="item.data.mainStatistic.values.maxDrawdownFormatted">%</span>
                </span>
              </td>
            </tr>
            <tr v-if="statColumns.winningMonths.visible || statColumns.sharpeRatio.visible">
              <td class="no-wrap">{{$t('tables.indices.columns.winningMonths')}}</td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.winningMonthsFormatted}} <span  v-if="item.data.mainStatistic.values.winningMonthsFormatted">%</span>
                </span>
              </td>
              <td class="no-wrap">
                {{$t('tables.indices.columns.sharpeRatio')}}
                </td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.sharpeRatioFormatted}} 
              </span>
              </td>
            </tr>
            <tr v-if="statColumns.sortinoRatio.visible || statColumns.calmarRatio.visible">
              <td class="no-wrap">{{$t('tables.indices.columns.sortinoRatio')}}</td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.sortinoRatioFormatted}} 
              </span>
              </td>
              <td class="no-wrap">
                {{$t('tables.indices.columns.calmarRatio')}}
                </td>
              <td class="text-primary font-weight-bold">
                <span v-if="item.data.mainStatistic">
                  {{item.data.mainStatistic.values.calmarRatioFormatted}} 
              </span>
              </td>
            </tr>
            <tr v-if="statColumns.externalId.visible">
              <td class="no-wrap">{{$t('tables.indices.columns.externalId')}}</td>
              <td class="text-primary font-weight-bold">
                {{(item.systemValues || {}).ExternalId}}
              </td>
              <td class="no-wrap">
             
              </td>
              <td class="text-primary font-weight-bold">
              
              </td>
            </tr>
          </table>
        
          <!-- <div class=" text-center" style="position: absolute;top: 30%;left:0; right:0; " v-if="composeIndexEnabled && !composeIndexItems.find(a => a.id === item.id)">
            <button style="opacity:0.8" class="btn  btn-lg btn-primary"  @click="selectComposeIndexPart(item)">Select</button>
          </div>

          <div  class=" text-center" style="position: absolute;top: 30%;left:0; right:0; " v-if="(screening.enabled && !screening.items.find(a => a.id === item.id)) && isIndexAllowedForScreening(item)">
            <button style="opacity:0.8"  class="btn  btn-lg btn-secondary"  @click="addIndexToScreening(item)">Select</button>
          </div> -->
        
          <!-- <div style="position: absolute;top: 0;right: 0;" v-if="!composeIndexEnabled && !screening.enabled">
          
            <b-dropdown left variant="white" v-if="isAdmin || item.allowEdit || item.allowDelete">
              <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
              </template>
              <b-dropdown-item v-if="isAdmin"  @click="uploadFile(item)">{{$t("tables.indices.uploadFile")}}</b-dropdown-item>
              <b-dropdown-item v-if="item.allowEdit"  :href="'indices/' + item.id + '/edit'">{{$t("tables.indices.edit")}}</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item v-if="item.allowDelete" @click="confirmDelete(item)">{{$t("common.delete")}}</b-dropdown-item>
            </b-dropdown>
          </div> -->
        </div>
      </div>
    </div>
    
  </div>
</template>