<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import FilterPanel from "./filter";
import List from "./list";
import Grid from "./grid.vue";
import chatgpt from '@/components/chatgpt.vue';

export default {
  components: {
    Layout,
    PageHeader,
    FilterPanel,
    List,
    Grid,
    chatgpt
},
  data() {
    return {
      title: 'Portfolio Instruments',
      initializing: true,
      viewMode: 'list',
      chatReady: false,
      breadcrumbs: [
        
      ],
      statColumns: {
        geography:{
          visible: true
        },
        provider:{
          visible: true
        },
        indexType:{
          visible: true
        },
        indexSubType:{
          visible: true
        },
        statUpdatedAt:{
          visible: true
        },
        instrumentType:{
          visible: true
        },
        assetClass:{
          visible: true
        },
        rebalanceFrequency:{
          visible: true
        },
        mtd:{
          visible: true
        },
        ytd:{
          visible: true
        },

        annualisedReturn:{
          visible: true
        },
        realisedVolatility:{
          visible: true
        },
        downsideVolatility:{
          visible: true
        },
        maxDrawdown:{
          visible: true
        },
        winningMonths:{
          visible: true
        },
        sharpeRatio:{
          visible: true
        },
        sortinoRatio:{
          visible: true
        },
        calmarRatio:{
          visible: true
        },
        externalId:{
          visible: false
        },
      },
      fieldsMap:{
        'Name': this.$t('tables.indices.columns.name'),
        'indexType': this.$t('tables.indices.columns.indexType'),
        'geography': this.$t('tables.indices.columns.market'),
        'provider':this.$t('tables.indices.columns.provider'),
        'assetClass': this.$t('tables.indices.columns.assetClass'),
        'instrumentType': this.$t('tables.indices.columns.instrumentType'),
        'rebalance-frequency': this.$t('tables.indices.columns.rebalanceFrequency'),
        'ytd': this.$t('tables.indices.columns.ytd'),
        'mtd': this.$t('tables.indices.columns.mtd'),
        'annualised-return': this.$t('tables.indices.columns.annualisedReturn'),
        'realised-volatility': this.$t('tables.indices.columns.realisedVolatility'),
        'downside-volatility': this.$t('tables.indices.columns.downsideVolatility'),
        'max-drawdown': this.$t('tables.indices.columns.maxDrawdown'),
        'winning-months': this.$t('tables.indices.columns.winningMonths'),
        'sharpe-ratio': this.$t('tables.indices.columns.sharpeRatio'),
        'sortino-ratio': this.$t('tables.indices.columns.sortinoRatio'),
        'calmar-ratio': this.$t('tables.indices.columns.calmarRatio'),
        'external-id': this.$t('tables.indices.columns.externalId')
      }
    };
  },
  created() {
    
    this.init();  
    if(this.signalrConnection) {
      this.chatReady = true;
    }
  },
  watch: {
   signalrConnection: {
      handler: function (newValue) {
        if(newValue) {
          this.chatReady = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters('signalr', {
      signalrConnection: 'signalrConnection'
    }),
    ...mapGetters('auth', {
      permissios: 'accountPermissions'
    }),
    ...mapGetters('accounts', {
      accountsLoading: 'loading',
      accounts: 'items'
    }),
    ...mapGetters('users', {
      usersLoading: 'loading',
      users: 'items'
    }),
    ...mapGetters('instruments', {
      order: 'order',
      instrumentsLoading: 'loading',
      processing: 'processing',
      classifiers: 'classifiers',
      total: 'total',
      settings: 'settings',
      page: 'page',
      perPage: 'perPage',
      filter: 'filter',
      normalized: 'normalized',
     
    }),
    isBusy (){
      return this.accountsLoading || this.usersLoading || this.instrumentsLoading || this.initializing;
    },
    normalisedPerformance: {
      get() {
        return this.normalized || false;
      },
      set (newValue) {
        this.setNormalized(newValue);
      }
    }
  },
  methods: {
    ...mapMutations('instruments', {
      setUser: 'SET_USER',
      setMode: 'SET_MODE',
      clearData: 'CLEAR_DATA'
    }),
    ...mapActions('instruments', {
      loadClassifiers: 'loadClassifiers',
      setSettings: 'setSettings',
      setPage: 'setPage',
      load: 'load',
      setNormalized: 'setNormalized',
      setOrder: 'setOrder'
    }),
    async init() {

      this.initializing = true;
      this.clearData();
      this.setUser(null);
      this.setMode('Subset');
      await this.loadClassifiers();
      await this.load();
      this.initializing = false;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="isBusy" />

    <div class="row">
      <div class="col-lg-12">
        <div class="email-leftbar" style="padding:0">
          <filter-panel></filter-panel>
        </div>
        <div class="email-rightbar">
          <div class="row">
            <div class="col-xl-12">
              <div class="card p-0 cardc8">
                <div class="card-content m-3">
                  <div class="row">
                    <div class="col-md-7">
                      <div class="card-header bg-transparent d-flex justify-content-between mb-2"><h5>Portfolio Instruments</h5></div>
                    </div>
                    <div class="col-md-5 text-right">
                      <div class="btn-group mr-2 mb-2 mb-sm-0">
                        <button type="button" class="btn btn-outline" :class="{'btn-outline-primary': viewMode === 'list'}" @click="viewMode = 'list'">
                          <i class="fa fa-list"></i>
                        </button>
                      </div>
                      <div class="btn-group mr-4 mb-2 mb-sm-0">
                        <button type="button" class="btn btn-outline " :class="{'btn-outline-primary': viewMode === 'grid'}"  @click="viewMode = 'grid'">
                          <i class="fa fa-th"></i>
                          </button>
                      </div> 
                      <div class="btn-group mr-2 mb-2 mb-sm-0">
                        
                          <b-dropdown left variant="white">
                            <template v-slot:button-content>
                              <i class="fa fa-columns m-0 text-muted font-size-20"></i>
                            </template>
                            <div class="dropdown-item bg-white text-body">
                              <div class="form-group"><input type="checkbox" v-model="statColumns.indexType.visible" id="stateColumns_indexType"/>
                                <label for="stateColumns_indexType" class="ml-2">{{$t('tables.indices.columns.indexType')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.geography.visible" id="stateColumns_market"/>
                                <label for="stateColumns_market" class="ml-2">{{$t('tables.indices.columns.market')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.provider.visible" id="stateColumns_provider"/>
                                <label for="stateColumns_provider" class="ml-2">{{$t('tables.indices.columns.provider')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.assetClass.visible" id="stateColumns_assetClass"/>
                                <label for="stateColumns_assetClass" class="ml-2">{{$t('tables.indices.columns.assetClass')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.instrumentType.visible" id="stateColumns_instrumentType"/>
                                <label for="stateColumns_instrumentType" class="ml-2">{{$t('tables.indices.columns.instrumentType')}}</label>
                              </div>
                                <div class="form-group"><input type="checkbox" v-model="statColumns.ytd.visible" id="stateColumns_ytd"/>
                                <label for="stateColumns_ytd" class="ml-2">{{$t('tables.indices.columns.ytd')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.mtd.visible" id="stateColumns_mtd"/>
                                <label for="stateColumns_mtd" class="ml-2">{{$t('tables.indices.columns.mtd')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.annualisedReturn.visible" id="stateColumns_annualisedReturn"/>
                                <label for="stateColumns_annualisedReturn" class="ml-2">{{$t('tables.indices.columns.annualisedReturn')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.realisedVolatility.visible" id="stateColumns_realisedVolatility"/>
                                <label for="stateColumns_realisedVolatility" class="ml-2">{{$t('tables.indices.columns.realisedVolatility')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.downsideVolatility.visible" id="stateColumns_downsideVolatility"/>
                                <label for="stateColumns_downsideVolatility" class="ml-2">{{$t('tables.indices.columns.downsideVolatility')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.maxDrawdown.visible" id="stateColumns_maxDrawdown"/>
                                <label for="stateColumns_maxDrawdown" class="ml-2">{{$t('tables.indices.columns.maxDrawdown')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.winningMonths.visible" id="stateColumns_winningMonths"/>
                                <label for="stateColumns_winningMonths" class="ml-2">{{$t('tables.indices.columns.winningMonths')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.sharpeRatio.visible" id="stateColumns_sharpeRatio"/>
                                <label for="stateColumns_sharpeRatio" class="ml-2">{{$t('tables.indices.columns.sharpeRatio')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.sortinoRatio.visible" id="stateColumns_sortinoRatio"/>
                                <label for="stateColumns_sortinoRatio" class="ml-2">{{$t('tables.indices.columns.sortinoRatio')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.calmarRatio.visible" id="stateColumns_calmarRatio"/>
                                <label for="stateColumns_calmarRatio" class="ml-2">{{$t('tables.indices.columns.calmarRatio')}}</label>
                              </div>
                              <div class="form-group"><input type="checkbox" v-model="statColumns.externalId.visible" id="stateColumns_externalId"/>
                                <label for="stateColumns_externalId" class="ml-2">{{$t('tables.indices.columns.externalId')}}</label>
                              </div>
                            </div>
                          </b-dropdown>

                          <b-dropdown left variant="white" ref="sortByDropdown">
                            <template v-slot:button-content>
                              <b class="mr-2" style="">{{fieldsMap[order.by]}}</b>
                              <i class="fas text-muted fa-sort-alpha-up mr-2" v-if="!order.asc"/>
                              <i class="fas text-muted fa-sort-alpha-down mr-2" v-if="order.asc"/>
                              <i class="fas fa-caret-down"/>
                            </template>
                            <b-dropdown-form>
                              <select class="form-control " 
                                :disabled="isBusy"
                                :value="order.by" 
                                style="width:200px" 
                                @change="($event) => setOrder({by:$event.target.value, asc: order.asc})"
                              >
                                <option value="Name">Name</option>
                                <option value="indexType">{{$t('tables.indices.columns.indexType')}}</option>
                                <option value="geography">{{$t('tables.indices.columns.market')}}</option>
                                <option value="provider">{{$t('tables.indices.columns.provider')}}</option>
                                <option value="assetClass">{{$t('tables.indices.columns.assetClass')}}</option>
                                <option value="instrumentType">{{$t('tables.indices.columns.instrumentType')}}</option>
                                
                                <option value="ytd">{{$t('tables.indices.columns.ytd')}}</option>
                                <option value="mtd">{{$t('tables.indices.columns.mtd')}}</option>
                                <option value="annualised-return">{{$t('tables.indices.columns.annualisedReturn')}}</option>
                                <option value="realised-volatility">{{$t('tables.indices.columns.realisedVolatility')}}</option>
                                <option value="downside-volatility">{{$t('tables.indices.columns.downsideVolatility')}}</option>
                                <option value="max-drawdown">{{$t('tables.indices.columns.maxDrawdown')}}</option>
                                <option value="winning-months">{{$t('tables.indices.columns.winningMonths')}}</option>
                                <option value="sharpe-ratio">{{$t('tables.indices.columns.sharpeRatio')}}</option>
                                <option value="sortino-ratio">{{$t('tables.indices.columns.sortinoRatio')}}</option>
                                <option value="calmar-ratio">{{$t('tables.indices.columns.calmarRatio')}}</option>
                              </select>
                              <div class="row">
                                <div class="col  mt-2">
                                  <button type="button" class="btn btn-block" 
                                    :disabled="isBusy"
                                    @click="setOrder({by:order.by, asc: true})"
                                    :class="{' btn-outline-secondary': !order.asc, ' btn-secondary': order.asc}"
                                    >
                                    <i class="fas fa-sort-alpha-up mr-2"></i>Asc
                                  </button>
                                </div>
                                <div class="col mt-2">
                                  <button type="button" class="btn btn-outline-secondary btn-block"
                                    :disabled="isBusy"
                                    @click="setOrder({by:order.by, asc: false})"
                                    :class="{' btn-outline-secondary': order.asc, ' btn-secondary': !order.asc}"
                                    >
                                    <i class="fas fa-sort-alpha-down mr-2"></i>Desc
                                  </button>
                                </div>
                              </div>
                            </b-dropdown-form>
                            <div class="p-2 pl-4 pr-4">
                              <b-button variant="link" block @click="() => $refs.sortByDropdown.hide(true)">Close</b-button>
                            </div>
                            
                          </b-dropdown>
                      </div>
                    </div>
                  </div>
                   <div>
                    <b-form-checkbox v-model="normalisedPerformance" switch class="mr-1" style="display:inline-block;">
                    </b-form-checkbox>{{$t("pages.indexView.normalisedPerformance")}}
                  </div>
                 
                  <list v-if="viewMode === 'list'" :statColumns="statColumns" :normalized="normalisedPerformance"></list>
                  <grid v-if="viewMode === 'grid'" :statColumns="statColumns" :normalized="normalisedPerformance"></grid>
                  <b-pagination
                    class="mr-3 float-right"
                    :value="page"
                    :total-rows="total"
                    :per-page="perPage"
                    @input="setPage"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>

       <chatgpt 
          v-if="chatReady"
          case-state="BrowseInstruments"
        >
        </chatgpt>
    </div>
  </Layout>
</template>